import { Accordion, Flex } from '@chakra-ui/react'
import { baseScrollBar } from 'constants/scrollbar'
import React from 'react'
import ReactPaginate from 'react-paginate'
import SectionItem from './SectionItem'

const SectionsContent = ({
  items,
  pageCount,
  handleChangePage,
  page,
  slug,
}) => {
  return (
    <Flex direction='column' overflow='hidden'>
      <Flex justify='center' mb='20px'>
        {items && pageCount > 1 && (
          <ReactPaginate
            onPageChange={handleChangePage}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            initialPage={Number(page)}
            previousLabel='< '
            nextLabel=' >'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </Flex>

      <Accordion
        allowMultiple
        w='100%'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
      >
        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
        >
          {items.map(section => (
            <SectionItem
              key={section.id}
              item={section}
              page={page}
              slug={slug}
            />
          ))}
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default SectionsContent
