import { useQuery } from '@apollo/client'
import { Button, Flex, Input } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { API_V2 } from 'constants/apollo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfoElement from 'screens/Comments/InfoElement/InfoElement'
import { GET_ALL_SCREENS } from 'services/screens/graphql'
import ScreenTypeSelector from '../component/ScreenTypeSelector'
import useSearchQuery from 'hooks/useSearchQuery'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/app'
import CreateScreen from '../component/CreateScreen'
import ScreensContent from '../component/ScreensContent'
import { colors } from 'shared/style/colors'
import ManageScreen from '../component/ManageScreen'

const COUNT = 10

const NewScreensLayout = () => {
  const [place, setPlace] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [, setQuery] = useState('')
  const navigate = useNavigate()
  const { create, page, slug, manage } = useSearchQuery()

  const isContentCreation = create === 'yes'
  const isManageContent = manage === 'yes'

  const isContentWorker = isContentCreation || isManageContent

  const initialVariables = useMemo(() => {
    const base = {
      pagination: {
        offset: isNaN(page) ? 0 : Number(page) * COUNT,
        count: COUNT,
      },
      screen: {},
    }

    if (place) {
      base.screen.place = place
    }

    if (slug) {
      base.screen.slugId = slug
    }

    return base
  }, [place, page, slug])

  const {
    data: screens,
    loading: isScreensLoading,
    refetch,
  } = useQuery(GET_ALL_SCREENS, {
    variables: initialVariables,
    context: {
      clientName: API_V2,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSearchChange = useCallback(e => {
    setSearchValue(e.target.value)
  }, [])

  const handleSetQuery = useCallback(
    value => {
      setQuery(value)
      navigate(
        `${window.location.pathname}?active=screens&page=0&slug=${value}`,
      )
    },
    [navigate],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
    [],
  )

  const toggleScreenType = type => {
    setPlace(type)
  }

  const clearSelections = () => {
    toggleScreenType('')
    setSearchValue('')
  }

  const handleCreateScreen = () => {
    navigate(
      `${window.location.pathname}?active=screens${
        isContentWorker ? '&page=0&slug=' : '&create=yes'
      }`,
    )
  }

  const handleChangePage = event => {
    navigate(
      `${window.location.pathname}?active=screens&page=${event.selected}&slug=${slug}`,
    )
  }

  const isNoContent =
    !isScreensLoading &&
    screens &&
    screens.items.screens.length === 0 &&
    !isContentCreation

  const handleRefetch = () => {
    refetch({
      variables: initialVariables,
      context: {
        clientName: API_V2,
      },
      fetchPolicy: 'cache-and-network',
    })
  }

  useEffect(() => {
    handleRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (!isContentWorker) {
      debouncedSearchChange(searchValue)
    }
  }, [searchValue, debouncedSearchChange, isContentWorker])

  return (
    <Flex w='100%' h='100%' direction='column' overflow='hidden'>
      <Flex justify='space-between' align='center' mb='24px'>
        <Flex
          align='center'
          gap='12px'
          visibility={isContentWorker ? 'hidden' : 'visible'}
        >
          <Input
            h='56px'
            minH='56px'
            type='text'
            placeholder='Slug'
            variant='loginField'
            value={searchValue}
            onChange={handleSearchChange}
            _placeholder={{
              color: colors['primary-gray-100'],
            }}
            py='9px'
          />
          <ScreenTypeSelector
            toggleScreenType={toggleScreenType}
            place={place}
          />
          <Button
            type='button'
            variant='outlineButton'
            w='fit-content'
            onClick={clearSelections}
          >
            Clear
          </Button>
        </Flex>

        <Button
          type='button'
          variant='defaultButton'
          w='204px'
          onClick={handleCreateScreen}
        >
          {isContentWorker ? 'Close' : 'Create Screen'}
        </Button>
      </Flex>

      {isScreensLoading && <LoadingSpinner />}

      {isNoContent && (
        <InfoElement
          title={`No screens data`}
          image='/images/hero/in_review.png'
        />
      )}

      {isContentCreation && <CreateScreen />}

      {isManageContent && <ManageScreen />}

      {!isContentWorker && screens && screens.items.screens.length > 0 && (
        <ScreensContent
          items={screens.items.screens}
          pageCount={Math.ceil(screens.items.pagination.total / COUNT)}
          handleChangePage={handleChangePage}
          page={page}
          slug={slug}
        />
      )}
    </Flex>
  )
}

export default NewScreensLayout
